// Style

import "../sass/main.scss";
import "./functions/main-page";


$(document).ready(() => {
  
});

$('#openNav').on('click', function() {
    $('#myNav').css("height", "100%");
});

$('#closeNav').on('click', function() {
    $('#myNav').css("height", "0%");
});

$("#ae").on("click",function(){
    $("#ae").css("display","none")
    $("#x").css("display","block")
});

$("#x").on("click",function(){
    $("#ae").css("display","block")
    $("#x").css("display","none")
});
